import React, { useState, useEffect } from 'react';

const Unauthorized = () => {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      Unauthorized
    </div>
  );
};

export default Unauthorized;
